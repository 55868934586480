var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "table-container"
  }, [_c('el-table', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tableLoading,
      expression: "tableLoading"
    }],
    attrs: {
      "data": _vm.tableData.data,
      "height": "50vh",
      "show-overflow-tooltip": true
    }
  }, [_c('el-table-column', {
    attrs: {
      "width": "120",
      "label": "Number",
      "prop": "number"
    }
  }), _c('el-table-column', {
    attrs: {
      "width": "170",
      "label": "Campaign Name",
      "prop": "campaign_name"
    }
  }), _c('el-table-column', {
    attrs: {
      "prop": "status",
      "label": "Dial Status",
      "width": "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.dial_status.toUpperCase() == 'ANSWERED' ? _c('p', {
          staticStyle: {
            "color": "#36d962",
            "margin": "0 auto"
          }
        }, [_vm._v(" ANSWERED ")]) : scope.row.dial_status.toUpperCase() == 'NO ANSWER' ? _c('p', {
          staticStyle: {
            "color": "#000",
            "margin": "0 auto"
          }
        }, [_vm._v(" NO ANSWER ")]) : scope.row.dial_status.toUpperCase() == 'PENDING' ? _c('p', {
          staticStyle: {
            "color": "#30a1f0",
            "margin": "0 auto"
          }
        }, [_vm._v(" PENDING ")]) : scope.row.dial_status.toUpperCase() == 'BUSY' ? _c('p', {
          staticStyle: {
            "color": "#ff0000",
            "margin": "0 auto"
          }
        }, [_vm._v(" BUSY ")]) : scope.row.dial_status.toUpperCase() == 'EXPIRED' ? _c('p', {
          staticStyle: {
            "color": "#f7921c",
            "margin": "0 auto"
          }
        }, [_vm._v(" EXPIRED ")]) : scope.row.dial_status.toUpperCase() == 'not_dialed_yet' ? _c('p', {
          staticStyle: {
            "color": "#30a1f0",
            "margin": "0 auto"
          }
        }, [_vm._v(" NOT DIALED YET ")]) : _vm._e()];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Answer Time",
      "prop": "answer_time",
      "width": "180"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "Hang Up Time",
      "prop": "hang_up_time",
      "width": "180"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "Duration",
      "prop": "duration",
      "width": "120"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "Retries",
      "prop": "retries",
      "width": "120"
    }
  }), _c('el-table-column', {
    attrs: {
      "label": "Refund",
      "prop": "refund",
      "width": "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.refund == 1 ? "Refunded" : "Pending") + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "Date & Time",
      "prop": "date_time",
      "width": "170"
    }
  })], 1), _c('div', {
    staticClass: "col-md-12",
    staticStyle: {
      "text-align": "center",
      "margin-top": "2em"
    }
  }, [_vm.tableData.data ? _c('el-pagination', {
    attrs: {
      "background": "",
      "layout": "prev, pager, next",
      "page-size": _vm.tableData.per_page || 0,
      "total": _vm.tableData.total || 0
    },
    on: {
      "current-change": _vm.handleCurrentChange
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };