import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexcharts: VueApexCharts
  },
  data: function () {
    return {
      campaign: "",
      colors: [],
      campaignLabels: [],
      CampaignCount: []
    };
  },
  computed: {
    chartOptions: function () {
      return {
        colors: this.colors,
        tooltip: {
          y: {
            formatter: undefined,
            title: {
              formatter: seriesName => {
                return seriesName.replace(/_/g, " ").replace(/\w\S*/g, function (txt) {
                  return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                });
              }
            }
          }
        },
        legend: {
          fontSize: "14px",
          position: "bottom",
          fontWeight: 400,
          floating: false,
          offsetX: 0,
          offsetY: 0,
          height: 200,
          itemMargin: {
            horizontal: 30,
            vertical: 15
          },
          formatter: function (seriesName, opts) {
            let total = 0;
            let series = opts.w.globals.series;
            let singleSeries = opts.w.globals.series[opts.seriesIndex];
            for (let i = 0; i < series.length; i++) {
              total += series[i];
            }
            let perCentage = parseInt(singleSeries / total * 100);
            seriesName = seriesName.replace(/_/g, " ").replace(/\w\S*/g, function (txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
            return `
                        <div style='display:inline-grid; width:300px'> 
                            <div style='grid-area: 1 / 1 / 2 / 2; place-self: start; width:100px;margin:0' >${singleSeries.toLocaleString()}</div>
                            <div style='grid-area: 1 / 2 / 2 / 3; place-self: center;text-align:center;width:100px'>${seriesName}</div>
                            <div style='grid-area: 1 / 3 / 2 / 4; place-self: end; width:100px;text-align:right;'>${perCentage}% </div>
                        </div>
                        `;
          }
        },
        dataLabels: {
          enabled: false
        },
        labels: this.campaignLabels,
        plotOptions: {
          pie: {
            size: 200,
            expandOnClick: false,
            donut: {
              size: "88%",
              background: "transparent",
              labels: {
                show: true,
                name: {
                  show: true,
                  offsetY: -10,
                  formatter: function (val) {
                    // remove underscore and capitalize each word after a space
                    return val.replace(/_/g, " ").replace(/\w\S*/g, function (txt) {
                      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                    });
                  }
                },
                value: {
                  show: true,
                  fontSize: "16px",
                  color: "#575761",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "29px",
                  lineHeight: "32px",
                  formatter: function (val) {
                    return val;
                  }
                },
                total: {
                  show: true,
                  label: "Total Messages",
                  color: "#575761",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "12px",
                  lineHeight: "13px",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  }
                }
              }
            }
          }
        },
        series: this.CampaignCount
      };
    },
    chartData() {
      return this.$store.state.admin.reseller.resellerReportsVoice.voice_status;
    }
  },
  watch: {
    chartData() {
      this.campaignLabels = [];
      this.CampaignCount = [];
      this.chartData.forEach(item => {
        this.campaignLabels.push(item.dial_status);
        this.CampaignCount.push(item.total);
      });
      this.campaignLabels.forEach(label => {
        if (label.toLowerCase() === "ANSWERED".toLowerCase()) {
          this.colors.push("#36D962");
        } else if (label.toLowerCase() === "NO ANSWER".toLowerCase()) {
          this.colors.push("#000");
        } else if (label.toLowerCase() === "PENDING".toLowerCase()) {
          this.colors.push("#30A1F0");
        } else if (label.toLowerCase() === "BUSY".toLowerCase()) {
          this.colors.push("#FF0000");
        } else if (label.toLowerCase() === "not_dialed_yet".toLowerCase()) {
          this.colors.push("#30A1F0");
        }
      });
      this.colors = this.colors.filter(e => e !== undefined);
    }
  }
};