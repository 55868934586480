export default {
  props: {
    tableLoading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleCurrentChange(val) {
      this.$emit("updatePage", val);
    }
  },
  computed: {
    tableData() {
      return this.$store.state.admin.reseller.resellerReportsVoice;
    }
  },
  mounted() {
    this.$store.dispatch("admin/reseller/getResellerDomainNames");
    this.$store.dispatch("admin/reseller/clearResellerReportsVoice");
  }
};